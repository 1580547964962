import PropTypes from 'prop-types'

const FormTextArea = ({
  label,
  className,
  icon: Icon,
  resize,
  required,
  ...props
}) => {
  const { id } = props
  return (
    <div className={`${className} relative`}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium mb-1.5">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <textarea
        id={id}
        required={required}
        {...props}
        className={`px-3.5 appearance-none block w-full py-2.5 bg-white/5 text-base font-normal border border-white/20 rounded-lg shadow-sm placeholder:text-white/20  focus:outline-none ${
          resize && resize
        }`}
      />
    </div>
  )
}

FormTextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.elementType,
  required: PropTypes.bool,
  className: PropTypes.string,
}

export default FormTextArea
