import React from 'react'
export default function Button({
  label,
  icon: Icon,
  active,
  className,
  isSecondary,
  ...rest
}) {
  return (
    <div
      className={`flex items-center justify-center flex-1 space-x-2 text-white px-[1.375rem] py-2 rounded-lg cursor-pointer ${
        active
          ? `bg-[#008000]`
          : isSecondary
          ? 'bg-[#fff]/10'
          : 'hover:bg-[#fff]/10'
      } `}
      {...rest}
    >
      {Icon && <Icon />}
      <span
        className={`text-sm font-medium font-inter whitespace-nowrap ${
          !active && 'text-white/50'
        }`}
      >
        {label}
      </span>
    </div>
  )
}
