import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import moment from 'moment'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Switch } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNoteToMulti,
  changeBetType,
  closeNotesModel,
  toggleAcceptAnyOdds,
  updateMultiBetPrice,
} from '@/redux/slices/bets'
import {
  addNoteToSingle,
  removeBetSlipSecondary,
  toggleBetSlipsMobile,
  toggleBetslips,
  updateMultipleSlips,
  updateSingleBetPrice,
  updateTotalBetPrice,
} from '@/redux/slices/betSlips'
import formatDate from '@/utils/formatDate'

import FormTextArea from '@/components/form-textarea'
import { getLeagueByName, getTeamByName } from '@/constants/countries-leagues'
import useBets from '@/hooks/useBets'
import { useMediaQuery } from 'react-responsive'
import { openNotesModel } from '@/redux/slices/bets'
import { handleConnectModal } from '@/redux/slices/wallet'
import XClose from '@/svgs/XClose'
import NextImage from '@/utils/Image'
import { NumberChip, renderNumber2 } from '@/utils/helper'
import { toPlainString } from '@/utils/toPlainString'
import { errorMsg, successMsg } from '@/utils/toast'
import { calculateMultiBetWinPrice } from '@/utils/bets'
import { gameOddSnapshotByIds } from '@/firebase/snapshots'
import Button from '@/components/modelButton'
import { firebaseGetUserInfoFromDb } from '@/firebase/utils'
import { setUserData } from '@/redux/slices/auth'
import { groupBy } from 'lodash'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'
import betsSelector from '@/redux/selectors/bets'
import walletSelector from '@/redux/selectors/wallet'
import { FormattedMessage } from 'react-intl'

const Model = dynamic(() => import('@/components/model'))
const NotesModel = memo(({ modelData, slips, multiBetNote }) => {
  const dispatch = useDispatch()
  const isOpen = modelData?.id || modelData?.forMulti ? true : false
  const isMulti = modelData?.forMulti ? true : modelData?.id && false
  const seletedSlip = useMemo(
    () => slips?.find((slip) => slip.id === modelData?.id),
    [isOpen],
  )
  const [tempNote, setTempNote] = useState(
    (isMulti ? multiBetNote : seletedSlip?.note) || '',
  )

  const handleClose = () => {
    dispatch(closeNotesModel())
    setTempNote('')
  }

  const handleSubmit = () => {
    if (!tempNote) return errorMsg('Kindly Add Bet Notes First')

    if (isMulti) {
      dispatch(addNoteToMulti(tempNote))
      successMsg('Bet Notes Added Successfully')
      handleClose()
    } else {
      dispatch(
        addNoteToSingle({
          note: tempNote,
          id: modelData?.id,
        }),
      )
      successMsg('Bet Notes Added Successfully')
      handleClose()
    }
  }

  useEffect(() => {
    if (isMulti) {
      setTempNote(multiBetNote || '')
    } else {
      const prevNote = seletedSlip?.note || ''
      setTempNote(prevNote || '')
    }
  }, [isOpen])

  return (
    <Model
      isOpen={isOpen}
      onClose={handleClose}
      showCloseIcon={true}
      className="max-w-md overflow-auto max-h-screen"
    >
      <div className="font-inter space-y-2 ">
        <h2 className="font-bold text-xl">Bet Notes</h2>
        <p className="text-base font-normal">
          This will make it easier for you to keep track of your betting details
          and can serve as a record for future bets.
        </p>
      </div>
      <div className="mt-12 flex flex-col space-y-6">
        <FormTextArea
          value={tempNote}
          onChange={(e) => setTempNote(e.target.value)}
          rows={6}
          label="Insert notes"
          placeholder="Type your bet notes."
          className="flex flex-col items-start"
        />
        <Button label="Save" active onClick={handleSubmit} />
      </div>
    </Model>
  )
})
NotesModel.displayName = 'NotesModel'

const ChangeOddsModel = memo(({ isOpen, onClickYes, handleClose }) => {
  return (
    <Model
      isOpen={isOpen}
      onClose={handleClose}
      showCloseIcon={true}
      className="max-w-md overflow-auto max-h-screen"
    >
      <div className="font-inter space-y-2">
        <p className="text-base font-normal">
          Odds are changed. Do you still want to place bet ?
        </p>
      </div>
      <div className="mt-12 flex flex-col space-y-6">
        <Button label="Yes" active onClick={onClickYes} />
        <Button isSecondary label="No" onClick={handleClose} />
      </div>
    </Model>
  )
})
ChangeOddsModel.displayName = 'ChangeOddsModel'

const TabButton = ({
  label,
  icon: Icon,
  betType,
  className,
  changeBtn,
  font,
  ...rest
}) => (
  <button
    onClick={() => changeBtn(label)}
    className={`${
      className
        ? ''
        : `flex items-center justify-center flex-1 space-x-2 text-white px-[1.375rem] py-2 rounded-lg cursor-pointer ${
            betType ? `bg-[#008000]` : 'hover:bg-[#fff]/10'
          }`
    }`}
    {...rest}
  >
    {Icon && <Icon alt="icon" />}
    <span
      className={`${
        className
          ? 'font-inter font-medium text-xs'
          : 'text-sm font-medium font-inter whitespace-nowrap'
      }`}
    >
      {label}
    </span>
  </button>
)

const SelectBetPrice = memo(
  ({
    betNotes,
    betPrice,
    onChange,
    onNoteClick,
    pathname,
    pastBet,
    price,
    betWon,
    possibleWinPrice,
    betDate,
    isLive,
    isProp = false,
    betType,
    globalView,
  }) => {
    const [selectionActive, setSelectionActive] = React.useState(false)
    // const { pastBet } = useSelector((state) => state.bets)
    const [tempValue, setValue] = useState(+betPrice === 0 ? '' : +betPrice)
    const options = [5, 25, 100]
    const limit = betType === 'multi' ? 10000 : isProp || isLive ? 250 : 5000

    const calculatePossibleWin =
      !possibleWinPrice &&
      (Number(price) > 0
        ? Number(betPrice) * (Number(price) / 100)
        : Number(betPrice) * (100 / Math.abs(Number(price))))

    useEffect(() => {
      const timeout = setTimeout(() => {
        const stringToNumber = +Math.abs(Number(tempValue))
        onChange && onChange(stringToNumber)
      }, 300)

      return () => {
        clearTimeout(timeout)
      }
    }, [tempValue])

    const onInputChange = (e) => {
      const val = e.target.value
      if (/^[0-9]+\.?[0-9]*$/.test(val)) {
        if (+val <= limit) {
          setValue(val)
        } else {
          setValue(Math.abs(limit))
        }
      } else {
        setValue('')
      }
    }

    return (
      <div className="space-y-[6px] ">
        {!pastBet ? (
          <>
            {globalView && (
              <>
                <div className="flex items-center justify-end">
                  <div
                    className="flex items-center gap-1 bg-white/5 hover:bg-white/10 duration-300 px-2 py-0.5 rounded-2xl cursor-pointer relative"
                    onClick={onNoteClick}
                  >
                    <NextImage
                      width={12}
                      height={12}
                      src="/svgs/file-plus.svg"
                      className="w-3 h-3"
                    />
                    <h4 className="text-[#98A2B3] font-inter font-normal text-xs">
                      Bet notes
                    </h4>
                  </div>
                </div>

                <div className="flex gap-2">
                  <div
                    className={`flex w-full flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
                      selectionActive ? 'border-[#008000]' : 'border-white/5'
                    } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
                    onClick={() => setSelectionActive(!selectionActive)}
                  >
                    <div className="my-auto w-full flex flex-col">
                      <span className="whitespace-nowrap text-xs text-gray-400">
                        Bet Amount
                      </span>
                      <div className="flex justify-center items-center">
                        <p className="text-[#F7941D] mr-1">$</p>
                        <input
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          value={tempValue}
                          onChange={onInputChange}
                          className="bg-transparent border-transparent w-full p-0"
                          // autoFocus
                        />
                      </div>
                    </div>
                  </div>

                  {betType === 'single' && (
                    <div
                      className={`flex flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
                        selectionActive ? 'border-[#008000]' : 'border-white/5'
                      } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
                    >
                      <div className="my-auto w-full flex flex-col">
                        <span className="whitespace-nowrap text-xs text-gray-400">
                          To Win
                        </span>
                        <div className="flex justify-center items-center">
                          <p className="text-[#F7941D] mr-1">$</p>
                          <input
                            type="text"
                            disabled
                            onWheel={(e) => e.target.blur()}
                            value={
                              calculatePossibleWin === 0
                                ? ''
                                : calculatePossibleWin
                            }
                            className="bg-transparent border-transparent w-full p-0"
                            // autoFocus
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  {selectionActive &&
                    options.map((option, index) => (
                      <div
                        key={option + index}
                        className={`w-16 h-10 flex items-center justify-center rounded-lg focus:ring-[#008000] focus:ring-2 focus:outline-none hover:bg-[#008000] mouse-pointer`}
                        onClick={() =>
                          setValue((prev) => {
                            return +prev + +option <= limit
                              ? +prev + +option
                              : limit
                          })
                        }
                      >
                        ${option}
                      </div>
                    ))}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">Date:</span>
              <span className="text-xs font-normal font-inter">{betDate}</span>
            </div>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">
                Bet amount:
              </span>
              <span className="text-xs font-normal font-inter">
                {betPrice || price}
              </span>
            </div>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">
                Est. Payout:
              </span>
              <span className="text-xs font-normal font-inter capitalize">
                {possibleWinPrice
                  ? Number(possibleWinPrice).toFixed(8)
                  : calculatePossibleWin.toFixed(8)}
              </span>
            </div>
            <div className="flex gap-4">
              {betWon === false || betWon === true ? (
                <div
                  className={`bg-[#12B76A]/10 ${
                    betWon ? 'text-[#12B76A]' : 'text-[#F04438]'
                  } capitalize rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
                >
                  {betWon ? 'Win' : 'Lost'}
                </div>
              ) : (
                <span className="inline-flex items-center gap-x-1.5 text-red-100 rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-red-400">
                  <svg
                    className="h-1.5 w-1.5 fill-red-500 animate-pulse"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Active
                </span>
              )}
            </div>
          </>
        )}
      </div>
    )
  },
)
SelectBetPrice.displayName = 'SelectBetPrice'

const RenderSingleSlip = memo(
  ({ item, matchDescription, league, pathname, isSettled, pastBet }) => {
    const dispatch = useDispatch()
    return (
      <SlipLayout
        label={matchDescription}
        settled={isSettled}
        iconUrl={league?.logo}
        pastBet={pastBet}
        onClose={() => {
          dispatch(removeBetSlipSecondary(item?.id))
        }}
      >
        <div className="space-y-3">
          <div className="flex flex-row items-center gap-3">
            <div className="relative">
              <NextImage
                width={40}
                height={40}
                src={
                  item?.teamName && item?.teamName !== 'draw'
                    ? getTeamByName({
                        teamName: item?.teamName,
                        league: league.name,
                        sport: item?.match?.sport,
                      })?.logo
                    : league?.logo
                }
                className="w-10 h-10"
              />
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {item?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!item?.teamName
                    ? item?.name
                    : item?.teamName && item?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  <NumberChip
                    number={renderNumber2(item?.price)}
                    // number2={renderNumber2(item?.bet_points && item?.price)}
                    className="bg-white/5 text-[#F7941D]"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center space-x-1 text-sm font-medium font-inter text-white">
                {!item?.teamName ? null : item?.teamName &&
                  item?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {item?.bet_points
                        ? item?.teamName + ' ' + '(' + item?.bet_points + ')'
                        : item?.teamName}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <SelectBetPrice
            betPrice={item?.betPrice || 0}
            onChange={
              !pastBet
                ? (newPrice) => {
                    dispatch(
                      updateSingleBetPrice({
                        betPrice: newPrice,
                        id: item?.id,
                      }),
                    )
                  }
                : ''
            }
            betNotes={item?.note}
            betWon={item?.won}
            possibleWinPrice={item?.possibleWinPrice}
            pathname={pathname}
            onNoteClick={() => dispatch(openNotesModel(item?.id))}
            pastBet={pastBet}
            price={item?.price}
            betDate={formatDate(
              moment.utc(item?.placedAt).local(),
              'MMM DD, YYYY',
            )}
            isProp={item?.isProp}
            isLive={item?.is_live}
            betType={'single'}
            globalView={true}
          />
        </div>
      </SlipLayout>
    )
  },
)
RenderSingleSlip.displayName = 'RenderSingleSlip'

const RenderMultiSlips = memo(
  ({
    item,
    isSameLeague,
    matchDescription,
    league,
    isSettled,
    pastBet,
    pathname,
    multiItem,
  }) => {
    console.log(pastBet)
    const dispatch = useDispatch()
    return (
      <SlipLayout
        isSameLeague={isSameLeague}
        settled={isSettled}
        label={matchDescription}
        pastBet={pastBet}
        iconUrl={league?.logo}
        onClose={() => {
          dispatch(removeBetSlipSecondary(item?.id))
        }}
      >
        <div className="space-y-3">
          <div className="flex flex-row items-center gap-3">
            <div className="relative">
              <NextImage
                width={40}
                height={40}
                src={
                  item?.teamName && item?.teamName !== 'draw'
                    ? getTeamByName({
                        teamName: item?.teamName,
                        league: league.name,
                        sport: item?.match?.sport,
                      })?.logo
                    : league?.logo
                }
                className="w-10 h-10"
              />
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {item?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!item?.teamName
                    ? item?.name
                    : item?.teamName && item?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  <NumberChip
                    number={renderNumber2(item?.price)}
                    // number2={renderNumber2(item?.bet_points && item?.price)}
                    className="bg-white/5 text-[#F7941D]"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center space-x-1 text-sm font-medium font-inter text-white">
                {!item?.teamName ? null : item?.teamName &&
                  item?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {item?.bet_points
                        ? item?.teamName + ' ' + '(' + item?.bet_points + ')'
                        : item?.teamName}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <SelectBetPrice
            betPrice={item?.betPrice || 0}
            onChange={
              !pastBet
                ? (newPrice) => {
                    dispatch(
                      updateSingleBetPrice({
                        betPrice: newPrice,
                        id: item?.id,
                      }),
                    )
                  }
                : ''
            }
            betNotes={multiItem ? multiItem?.note : item?.note}
            betWon={multiItem ? multiItem?.won : item?.won}
            possibleWinPrice={
              multiItem ? multiItem?.possibleWinPrice : item?.possibleWinPrice
            }
            pathname={pathname}
            onNoteClick={() => dispatch(openNotesModel(item?.id))}
            pastBet={pastBet}
            price={multiItem ? multiItem?.price : item?.price}
            betDate={formatDate(
              moment
                .utc(multiItem ? multiItem?.placedAt : item?.placedAt)
                .local(),
              'MMM DD, YYYY',
            )}
            isProp={item?.isProp}
            isLive={item?.is_live}
            betType={'multi'}
            globalView={false}
          />
        </div>
      </SlipLayout>
    )
  },
)
RenderMultiSlips.displayName = 'RenderMultiSlips'

const SlipLayout = memo(
  ({
    iconUrl,
    label,
    pastBet,
    children,
    onClose,
    settled,
    isSameLeague,
    ...rest
  }) => {
    return (
      <div
        className={`relative space-y-4 rounded-lg pb-4 overflow-hidden bg-white/5 `}
        {...rest}
      >
        <div
          className={`flex items-center justify-between py-3 pr-2 pl-6 ${
            isSameLeague || settled ? 'bg-[#4D000D]' : 'bg-white/5'
          }`}
        >
          <div className="flex items-center space-x-1 flex-1">
            {iconUrl && (
              <NextImage width={24} height={24} src={iconUrl} className="h-6" />
            )}
            <span className="text-xs font-medium font-inter truncate w-48">
              {label}
            </span>
          </div>
          {!pastBet && (
            <div
              onClick={onClose && onClose}
              className="cursor-pointer close-icon-right"
            >
              <XClose />
            </div>
          )}
        </div>
        {settled ? (
          <div className="absolute right-2 top-[2.2rem] text-[#4D000D] font-extrabold text-sm opacity-70">
            Settled
          </div>
        ) : null}
        <div className="px-6 pt-2">{children}</div>
      </div>
    )
  },
)
SlipLayout.displayName = 'SlipLayout'

const NoSlips = () => {
  return (
    <div className="2xl:py-24 py-0">
      <div className="flex flex-col justify-center items-center  ">
        <div className="">
          <NextImage
            src={'/img/lil-bit-icon.svg'}
            alt={'logo'}
            height={95}
            width={95}
          />
        </div>
        <h6 className=" mt-2.5 text-sm text-white/50">
          <FormattedMessage
            id="rightSidebar.emptyMessageImageDescription"
            defaultMessage="Bet Slip is Empty"
          />
        </h6>
        <h3 className="mt-2.5 text-base font-bold">
          <FormattedMessage
            id="rightSidebar.emptyMessagePrompt"
            defaultMessage="Bet a Lil Bit Now!"
          />
        </h3>
      </div>
    </div>
  )
}

function RightSidebar() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { user, isAuthenticated } = useSelector(authSelector)
  const { slips: BetsData, betPlaceLoad } = useSelector(betSlipsSelector)
  const { internalWallet } = useSelector(walletSelector)
  const { placedBets, isLoading } = useSelector(betSlipsSelector)
  const {
    betType,
    acceptAnyOdds,
    price: multiBetPrice,
    multibetNote,
    notesModel,
  } = useSelector(betsSelector)
  const { placeBetHandler, clearBetsHandler } = useBets()
  const [placeLoading, setPlaceBetLoader] = useState(false)
  const [oddChange, setOddChange] = useState(false)
  const [oddsModalVisible, setOddsModalVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(true)
  const [pastColor, setPastColor] = useState(false)
  const [pastBet, setPastBet] = useState(false)
  const [singleFilterBets, setSingleFilterBets] = useState([])
  const [multiFilterBets, setMultiFilterBets] = useState([])
  const [singleTotal, setSingleTotal] = useState(0)
  const [multiTotal, setMultiTotal] = useState(0)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const componentRef = useRef(null)
  const listingData = [...BetsData].reverse()
  const updateSlips = (payload) => {
    dispatch(updateMultipleSlips(payload))
  }

  useEffect(() => {
    if (listingData.length > 0) {
      const groupedData = groupBy(listingData, (item) => [item.match.id])
      Object.keys(groupedData).map((gameId) => {
        const oddIds = groupedData[gameId].map((group) => group.id)
        if (oddIds.length) {
          gameOddSnapshotByIds(gameId, oddIds, updateSlips)
        }
      })
    }
  }, [listingData])

  const oddData = listingData.map((data) => {
    return data.bet_points ? data.bet_points : data.price
  })

  const previousValue = useRef(null)

  let totalBetPrice =
    betType?.toLowerCase() === 'single'
      ? listingData?.reduce((acc, curr) => acc + Number(curr?.betPrice), 0)
      : multiBetPrice

  const possibleWinPrice =
    betType?.toLowerCase() === 'single'
      ? listingData?.reduce((acc, curr) => {
          const { betPrice, price } = curr
          if (Number(price) > 0) {
            return acc + Number(betPrice) * (Number(price) / 100)
          } else {
            return acc + Number(betPrice) * (100 / Math.abs(Number(price)))
          }
        }, 0)
      : // Calculate possible win price for multi bet with MultiBetPrice
        calculateMultiBetWinPrice(listingData, multiBetPrice)

  const onClickActions = (label) => {
    dispatch(changeBetType(label))
  }

  const arrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }

  const placeBetHandle = () => {
    if (!user?.uid) {
      return router.push('/login')
    }
    if (oddChange === true) {
      setOddsModalVisible(true)
      return
    }
    if (totalBetPrice < Number(internalWallet?.balanceUsd)) {
      betHandler()
    } else {
      return errorMsg('Not enough credit in user wallet.')
    }
  }

  const closeOddsModal = () => {
    setOddsModalVisible(false)
  }

  const checkContainsSettledBets = useMemo(
    () => listingData?.some((slip) => slip?.isSettled || false),
    [listingData],
  )

  const refetchUser = async () => {
    try {
      const data = await firebaseGetUserInfoFromDb(user.uid)
      return data
    } catch (error) {
      console.log('err', error)
    }
  }

  const betHandler = () => {
    if (!isAuthenticated) {
      router.push('/login')
      return null
    }

    if (!listingData?.length || listingData?.length === 0) {
      return errorMsg('No Bets Selected')
    }

    if (checkContainsSettledBets) {
      return errorMsg('Contains Settled Bet(s)')
    }

    if (Number(totalBetPrice) <= 0) {
      return errorMsg('Bet amount should be greater than 0')
    }

    if (
      Number(internalWallet?.balanceUsd) < 0 ||
      Number(internalWallet?.balanceUsd) === 0
    ) {
      dispatch(updateTotalBetPrice(totalBetPrice))
      dispatch(handleConnectModal(true))
      return
      // return errorMsg('Not enough credit in user wallet')
    }

    if (betType === 'single') {
      placeSingleBet()
    } else if (betType === 'multi') {
      if (listingData?.length === 1) {
        placeMultiIntoSingle()
      } else {
        placeMultiBet()
      }
    }
  }

  const placeMultiIntoSingle = () => {
    if (totalBetPrice + multiTotal > 10000) {
      return errorMsg('Total bet amount should not be more than $10000.')
    }

    setPlaceBetLoader(true)
    const isPriceExceed = listingData?.every((list) => {
      const validNum = list?.isProp || list?.is_live ? 250 : 5000
      return Number(list?.betPrice) < validNum
    })

    if (!isPriceExceed) {
      return errorMsg('Maximum bet limit exceeded.')
    }
    placeBetHandler({
      betSlips: [
        {
          ...listingData[0],
          betPrice: toPlainString(totalBetPrice || 0),
        },
      ],
      betType: 'single',
      price: toPlainString(totalBetPrice || 0),
      possibleWinPrice: toPlainString(possibleWinPrice || 0),
      onComplete: () => {
        setPlaceBetLoader(false)
        refetchUser().then((res) => {
          if (res && res.uid) {
            setUserData(res)
          }
        })
      },
    })
  }

  const placeSingleBet = () => {
    if (totalBetPrice + singleTotal > 10000) {
      return errorMsg('Total bet amount should not be more than $10000.')
    }

    const isPriceValid = listingData?.every(
      (list) => Number(list?.betPrice) > 0,
    )

    const isPriceExceed = listingData?.every((list) => {
      const validNum = list?.isProp || list?.is_live ? 250 : 5000
      return Number(list?.betPrice) < validNum
    })

    if (!isPriceValid) {
      return errorMsg("Every Bet's amount should be greater than 0")
    }

    if (!isPriceExceed) {
      return errorMsg('Maximum bet limit exceeded.')
    }

    setPlaceBetLoader(true)
    placeBetHandler({
      betSlips: listingData,
      betType: 'single',
      price: toPlainString(totalBetPrice || 0),
      possibleWinPrice: toPlainString(possibleWinPrice || 0),
      onComplete: () => {
        setPlaceBetLoader(false)
        refetchUser().then((res) => {
          if (res && res.uid) {
            setUserData(res)
          }
        })
      },
    })
  }

  const placeMultiBet = () => {
    // Validate bet price.
    if (totalBetPrice > 10000) {
      return errorMsg('Total bet amount should not be more than $10000.')
    }

    const isPossiblewinValid = Number(possibleWinPrice) > 10000

    if (isPossiblewinValid) {
      return errorMsg(
        'Your multi-bet exceeds our $10,000 limit. Please remove some bets or lower the amount',
      )
    }

    const containsDublicateLeague = listingData
      .map((item) => item?.match?.id)
      .some((item, index, arr) => arr.indexOf(item) !== index)

    if (containsDublicateLeague) {
      return errorMsg('You cannot place bet on 2 odds in the same Match')
    }

    setPlaceBetLoader(true)
    placeBetHandler({
      betSlips: listingData,
      betType: 'multi',
      note: multibetNote,
      price: toPlainString(totalBetPrice || 0),
      possibleWinPrice: toPlainString(possibleWinPrice || 0),
      onComplete: () => {
        setPlaceBetLoader(false)
      },
    })
  }

  const pastBetsHandler = () => {
    setActiveColor(false)
    setPastColor(true)
    setPastBet(true)
  }

  const activeBetsHandler = () => {
    setPastColor(false)
    setActiveColor(true)
    setPastBet(false)
  }

  useEffect(() => {
    previousValue.current = oddData
  }, [oddData])

  useEffect(() => {
    if (
      !acceptAnyOdds &&
      oddData.length > 0 &&
      previousValue.current.length > 0
    ) {
      const isEqual = arrayEquals(oddData, previousValue.current)
      if (!isEqual) {
        setOddChange(true)
      }
    } else if (acceptAnyOdds === true) {
      setOddChange(false)
    }
  }, [oddData, acceptAnyOdds])

  // infinite scrolling for past bets
  useEffect(() => {
    if (placedBets.length > 0) {
      const filteredBets = placedBets.reduce(
        (acc, curr) => {
          if (curr.betType === 'multi') {
            acc.multi.push(curr)
            if (curr.status === 'active')
              acc.multiTotal += Number(curr.betPrice)
          } else {
            acc.single.push(curr)
            if (curr.status === 'active')
              acc.singleTotal += Number(curr.betPrice)
          }

          return acc
        },
        { single: [], multi: [], singleTotal: 0, multiTotal: 0 },
      )
      setSingleFilterBets(filteredBets.single)
      setMultiFilterBets(filteredBets.multi)
      setSingleTotal(filteredBets.singleTotal)
      setMultiTotal(filteredBets.multiTotal)
    }
  }, [placedBets])

  const renderSlips = useMemo(
    () => (
      <div
        className={`p-4 space-y-2 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-[#1B2126]  h-[100vh]  ${
          listingData.length === 0 && !pastBet
            ? 'flex justify-center items-center'
            : ''
        }`}
        ref={componentRef}
      >
        {!pastBet ? (
          <>
            {listingData?.length === 0 ? (
              <NoSlips />
            ) : (
              <>
                {betType?.toLowerCase() === 'single' ? (
                  <>
                    {listingData?.map((item, index) => {
                      const league = getLeagueByName(item?.match?.league)
                      let matchDescription
                      if (item?.future) {
                        matchDescription = item.future
                      } else {
                        const homeTeam = getTeamByName({
                          teamName: item?.match?.home_team,
                          league: item?.match?.league,
                        })
                        const awayTeam = getTeamByName({
                          teamName: item?.match?.away_team,
                          league: item?.match?.league,
                        })
                        matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                      }
                      return (
                        <RenderSingleSlip
                          key={index + item?.id}
                          isSettled={item?.isSettled || false}
                          item={item}
                          matchDescription={matchDescription}
                          league={league}
                          pathname={router.pathname}
                          pastBet={pastBet}
                        />
                      )
                    })}
                  </>
                ) : (
                  <div className="flex flex-col space-y-2">
                    {listingData?.map((item, index) => {
                      const league = getLeagueByName(item?.match?.league)
                      let matchDescription
                      if (item?.future) {
                        matchDescription = item.future
                      } else {
                        const homeTeam = getTeamByName({
                          teamName: item?.match?.home_team,
                          league: item?.match?.league,
                        })
                        const awayTeam = getTeamByName({
                          teamName: item?.match?.away_team,
                          league: item?.match?.league,
                        })
                        matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                      }

                      const isSameLeague =
                        listingData?.reduce(
                          (acc, curr) =>
                            (acc +=
                              curr?.match?.id === item?.match?.id ? 1 : 0),
                          0,
                        ) > 1

                      return (
                        <RenderMultiSlips
                          key={item?.id + index}
                          item={item}
                          isSettled={item?.isSettled || false}
                          isSameLeague={isSameLeague}
                          matchDescription={matchDescription}
                          league={league}
                          pastBet={pastBet}
                        />
                      )
                    })}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {betType?.toLowerCase() === 'single' ? (
              <>
                {singleFilterBets.length > 0 &&
                  singleFilterBets?.map((item, index) => {
                    const league = getLeagueByName(item?.match?.league)
                    let matchDescription
                    if (item?.future) {
                      matchDescription = item.future
                    } else {
                      const homeTeam = getTeamByName({
                        teamName: item?.match?.home_team,
                        league: item?.match?.league,
                      })
                      const awayTeam = getTeamByName({
                        teamName: item?.match?.away_team,
                        league: item?.match?.league,
                      })
                      matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                    }
                    return (
                      <RenderSingleSlip
                        key={index + item?.id}
                        isSettled={item?.isSettled || false}
                        item={item}
                        matchDescription={matchDescription}
                        league={league}
                        pathname={router.pathname}
                        pastBet={pastBet}
                      />
                    )
                  })}
                {isLoading && <p>Loading ....</p>}
              </>
            ) : (
              <>
                {multiFilterBets.length > 0 &&
                  multiFilterBets?.map((multi) => {
                    return multi.betSlips.map((item, index) => {
                      const league = getLeagueByName(item?.match?.league)
                      let matchDescription
                      if (item?.future) {
                        matchDescription = item.future
                      } else {
                        const homeTeam = getTeamByName({
                          teamName: item?.match?.home_team,
                          league: item?.match?.league,
                        })
                        const awayTeam = getTeamByName({
                          teamName: item?.match?.away_team,
                          league: item?.match?.league,
                        })
                        matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                      }

                      const isSameLeague =
                        placedBets?.betSlips?.reduce(
                          (acc, curr) =>
                            (acc +=
                              curr?.match?.id === item?.match?.id ? 1 : 0),
                          0,
                        ) > 1

                      return (
                        <RenderMultiSlips
                          key={item?.id + index}
                          multiItem={multi}
                          item={item}
                          isSettled={item?.isSettled || false}
                          isSameLeague={isSameLeague}
                          matchDescription={matchDescription}
                          league={league}
                          pathname={router.pathname}
                          pastBet={pastBet}
                        />
                      )
                    })
                  })}
                {isLoading && <p>Loading ....</p>}
              </>
            )}
          </>
        )}
      </div>
    ),
    [listingData, betType, pastBet, placedBets, user],
  )

  const onClickClose = () => {
    if (sm) {
      dispatch(toggleBetSlipsMobile(false))
      return
    }
    dispatch(toggleBetslips())
  }
  return (
    <>
      <div className="w-[19rem] hidden lg:block  " />
      <div
        className={`${
          sm ? 'mx-0' : 'mx-8'
        } lg:mx-0 lg:w-[19rem] lg:absolute lg:right-0 rounded-lg lg:rounded-tl-lg  overflow-auto  ${
          sm
            ? 'h-[92vh]'
            : 'max-h-[calc(100vh-6rem)] lg:min-h-[calc(100vh-6rem)] min-h-[54rem]'
        }
         flex flex-col ${
           router.pathname === '/home' ? 'bg-[#0E1116]' : 'bg-white/5'
         } `}
      >
        <div className="relative px-2 pt-5 pb-4 bg-white/5 space-y-2 ">
          <div
            className="block sm:hidden absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
            onClick={onClickClose}
          >
            <NextImage
              width={20}
              height={20}
              src="/svgs/x-close.svg"
              alt="close button"
            />
          </div>
          <div className="flex flex-row items-center justify-center gap-x-4 sm:justify-between sm:gap-1 px-2">
            <div
              className={` ${
                activeColor ? 'bg-[#008000]' : ''
              } flex flex-row items-center gap-2 px-2 rounded-[40px]`}
            >
              <TabButton
                label="BET SLIP"
                className
                onClick={activeBetsHandler}
              />
              <NumberChip
                number={listingData?.length}
                className="bg-[#008000]"
              />
            </div>
            {user && user.uid && (
              <div
                className={` ${
                  pastColor ? 'bg-[#008000]' : ''
                } flex flex-row items-center gap-1 cursor-pointer px-2 rounded-[40px]`}
              >
                <TabButton
                  label="Placed Bets"
                  className
                  onClick={pastBetsHandler}
                />
                <NumberChip
                  number={placedBets.length}
                  className="bg-[#008000]"
                />
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-row gap-2 flex-wrap items-center justify-between p-2 bg-white/10 rounded-xl relative">
            <TabButton
              // disabled={listingData?.length > 1}
              betType={betType?.toLowerCase() === 'single'}
              label="Single"
              icon={(props) => (
                <NextImage
                  width={20}
                  height={20}
                  src="/svgs/single-ticket.svg"
                  {...props}
                />
              )}
              onClick={() => {
                onClickActions('single')
              }}
            />
            <TabButton
              // disabled={listingData?.length === 1}
              betType={betType?.toLowerCase() === 'multi'}
              label="Multi"
              icon={(props) => (
                <NextImage
                  width={20}
                  height={20}
                  src="/svgs/multi-ticket.svg"
                  {...props}
                />
              )}
              onClick={() => {
                onClickActions('multi')
              }}
            />
          </div>
        </div>
        {renderSlips}
        {!pastBet && (
          <>
            <div className="border-b border-white/20 mt-auto flex justify-end">
              <p
                className="font-normal text-xs p-4 cursor-pointer hover:text-[#F7941D]"
                onClick={clearBetsHandler}
              >
                <FormattedMessage
                  id="rightSidebar.footerClearAll"
                  defaultMessage="Clear all"
                />
              </p>
            </div>
            <div className="p-4 pb-32 sm:pb-6 space-y-3">
              {betType?.toLowerCase() === 'single' ? null : (
                <>
                  {listingData?.length > 0 && (
                    <SelectBetPrice
                      betPrice={multiBetPrice}
                      onChange={(newPrice) => {
                        dispatch(updateMultiBetPrice(newPrice))
                      }}
                      betType={betType}
                      pathname={router.pathname}
                      onNoteClick={() => dispatch(openNotesModel(false))}
                      globalView={true}
                    />
                  )}
                </>
              )}
              <div className="flex flex-row justify-between flex-wrap gap-3">
                <p className="font-normal text-xs font-inter">
                  <FormattedMessage
                    id="rightSidebar.footerAcceptOddsChanges"
                    defaultMessage=" Accept any odds changes"
                  />
                </p>
                <Switch
                  checked={acceptAnyOdds}
                  onChange={() => {
                    dispatch(toggleAcceptAnyOdds())
                  }}
                  className={`${
                    acceptAnyOdds ? 'bg-[#F7941D]' : 'bg-white/50'
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Accept any odds changes</span>
                  <span
                    className={`${
                      acceptAnyOdds ? 'translate-x-4' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <button
                className={`flex w-full items-center justify-center space-x-2 text-white px-[1.375rem] transition duration-200 disabled:opacity-50 py-2 rounded-lg active-mouse-pointer`}
                disabled={!totalBetPrice || betPlaceLoad || placeLoading}
                onClick={placeBetHandle}
              >
                <div className="h-5 flex justify-center items-center">
                  {betPlaceLoad || placeLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline w-5 h-5 text-white animate-spin fill-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <NextImage
                      width={20}
                      height={20}
                      src="/svgs/single-ticket.svg"
                      alt="ticket"
                    />
                  )}
                </div>
                <span className="text-sm font-medium font-inter whitespace-nowrap">
                  <FormattedMessage
                    id="rightSidebar.footerPlaceBetAmount"
                    defaultMessage="Place bet"
                  />{' '}
                  {totalBetPrice
                    ? Number.isInteger(totalBetPrice)
                      ? totalBetPrice.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 20,
                        })
                      : Number(totalBetPrice).toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 20,
                        })
                    : '0.00'}{' '}
                  USD
                </span>
              </button>
              <div className="font-inter text-xs flex flex-row items-center justify-end gap-3 flex-wrap">
                <p className="font-normal">
                  <FormattedMessage
                    id="rightSidebar.footerPossibleWin"
                    defaultMessage="Possible win"
                  />
                </p>
                <p className="font-semibold text-[#F7941D]">
                  ${Number(possibleWinPrice)?.toFixed(9)}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <NotesModel
        modelData={notesModel}
        slips={listingData}
        multiBetNote={multibetNote}
      />
      <ChangeOddsModel
        isOpen={oddsModalVisible}
        onClickYes={betHandler}
        handleClose={closeOddsModal}
      />
    </>
  )
}

export default memo(RightSidebar)
