import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import maxBy from 'lodash/maxBy'
import toLower from 'lodash/toLower'
import mapKeys from 'lodash/mapKeys'
import includes from 'lodash/includes'
import find from 'lodash/find'

function generateKeyName(odd) {
  if (odd.bet_points) {
    return odd.name
      .replace(odd.bet_points.toString(), '')
      .trim()
      .replace('+', '')
      .trim()
      .toLowerCase()
  } else if (odd.bet_points === 0) {
    return odd.name.replace('+0', '').trim().toLowerCase()
  } else {
    return odd?.name?.toLowerCase()
  }
}

const sportBetCategories = {
  baseball: ['player', 'inning'],
  basketball: ['player', 'quarters', 'halves'],
  football: ['player', 'quarters', 'halves'],
  hockey: ['player', 'periods'],
  // might be we need to add soccer, mma and boxing as well
}

export const oddsFormatter = (sport) => (odds) => {
  if (!odds) return {}

  const marketGroups = mapKeys(groupBy(odds, 'market_name'), (_, k) =>
    toLower(k),
  )
  const betSubGroups = mapValues(marketGroups, (group) =>
    groupBy(group, (odd) => generateKeyName(odd)),
  )

  const tabs = groupBy(Object.entries(betSubGroups), ([key, data]) => {
    if (sport === 'football' && key === 'anytime touchdown scorer') {
      return 'player'
    }
    const category = find(sportBetCategories[sport], (category) =>
      includes(key, category),
    )
    return category || 'game'
  })
  return mapValues(tabs, Object.fromEntries)
}

export const mainOddsFormatter = (odds) => {
  if (!odds) return {}

  const marketGroups = mapKeys(groupBy(odds, 'market_name'), (_, k) =>
    toLower(k),
  )
  const betSubGroups = mapValues(marketGroups, (group) =>
    groupBy(group, (odd) => generateKeyName(odd)),
  )
  const latestBets = mapValues(betSubGroups, (subGroup) =>
    mapValues(subGroup, (odd) => maxBy(odd, 'timestamp')),
  )

  return latestBets
}
export const oddsFormatterBet = (sport) => (odds) => {
  if (!odds) return {}

  const marketGroups = mapKeys(groupBy(odds, 'market_name'), (_, k) =>
    toLower(k),
  )
  const betSubGroups = mapValues(marketGroups, (group) =>
    groupBy(group, (odd) => generateKeyName(odd)),
  )

  return Object.entries(betSubGroups).length
}
