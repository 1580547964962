import React, { memo, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { Transition as ContentTransaction } from 'react-transition-group'
import RightSidebar from '../RightSidebar'
import SharedLayout from '../shared-layout'
import Bets from '@/components/pages-partials/bets'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'

function RightSidebarLayout({
  title,
  children,
  showIcons,
  footer = footer,
  sidebar = true,
  className = '',
  sportsidebar,
  footballIcon,
  isHome,
}) {
  const { slips, betslipsOpen, betslipsOpenMobile } =
    useSelector(betSlipsSelector)
  const { user } = useSelector(authSelector)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const [showRightSidebar, setShowRightSidebar] = useState(false)

  const calculateShowRightSidebar = () => {
    if (isHome === true && user?.roleType === 'admin') {
      return false
    } else if (isHome === true) {
      if (sm) {
        return betslipsOpenMobile
      } else {
        return slips?.length > 0
      }
    } else if (user?.roleType === 'admin') {
      return false
    } else {
      if (sm) {
        return betslipsOpenMobile
      } else {
        return betslipsOpen
      }
    }
  }

  useEffect(() => {
    const newShowRightSidebar = calculateShowRightSidebar()
    if (newShowRightSidebar !== showRightSidebar) {
      setShowRightSidebar(newShowRightSidebar)
    }
  }, [isHome, user, sm, slips, betslipsOpen, betslipsOpenMobile])

  return (
    <SharedLayout
      sidebar={sidebar}
      showRightSidebar={showRightSidebar}
      footer
      title={title}
      sportsidebar={sportsidebar}
      footballIcon={footballIcon}
      showIcons={showIcons}
      isHome
    >
      <div className="flex flex-col lg:flex-row gap-4 ">
        <ContentTransaction in={showRightSidebar} timeout={300}>
          <div
            className={`${
              showRightSidebar ? 'lg:w-[calc(100%-20rem)]' : 'lg:w-[100%]'
            }`}
          >
            <div className={className}>{children}</div>
          </div>
        </ContentTransaction>
        <Transition
          show={showRightSidebar}
          enter="transition-transform duration-700 sm:transition ease-out duration-300"
          enterFrom="transform translate-x-full"
          enterTo="transform translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform translate-x-0"
          leaveTo="transform translate-x-full"
          className={`fixed right-0 z-20  ${
            sm
              ? 'top-0 inset-0 backdrop-blur-md bg-black/90 '
              : 'sidebar top-24 '
          }  `}
        >
          <RightSidebar />
        </Transition>
        <Transition
          show={!showRightSidebar}
          enter="transition ease-out duration-300"
          enterFrom="transform translate-x-full"
          enterTo="transform translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform translate-x-0 "
          leaveTo="transform translate-x-full"
        >
          <div className="block w-4">&nbsp;</div>
        </Transition>
      </div>
      {!isHome && (
        <Bets
          showRightSidebar={showRightSidebar}
          asComponent={true}
          isHomeLayout={true}
        />
      )}
    </SharedLayout>
  )
}

export default memo(RightSidebarLayout)
