import authSelector from '@/redux/selectors/auth'
import { resetBets } from '@/redux/slices/bets'
import { clearBetSlips, placeBet } from '@/redux/slices/betSlips'
import { randomId } from '@/utils/randomId'
import { toPlainString } from '@/utils/toPlainString'
import { useDispatch, useSelector } from 'react-redux'

export default function useBets() {
  const dispatch = useDispatch()
  const { user } = useSelector(authSelector)

  const replaceNull = (data) =>
    JSON.parse(JSON.stringify(data).replace(/null/g, false))

  const clearBetsHandler = () => {
    dispatch(clearBetSlips())
    dispatch(resetBets())
  }
  const placeBetHandler = async ({ onComplete, ...data }) => {
    let firebaseData

    if (data.betType === 'single') {
      const withStatus = data?.betSlips?.map((slip) => {
        return {
          ...slip,
          betPrice: toPlainString(slip.betPrice),
          status: 'active',
          betID: randomId(5),
          placedAt: new Date(),
        }
      })

      firebaseData = replaceNull({
        ...data,
        betSlips: withStatus,
        accessToken: user.accessToken,
        referrerInfo: {
          username: user?.username,
          displayName: user?.displayName,
        },
      })
    } else {
      firebaseData = replaceNull({
        ...data,
        status: 'active',
        referrerInfo: {
          username: user?.username,
          displayName: user?.displayName,
        },
        betID: randomId(5),
        accessToken: user.accessToken,
        placedAt: new Date(),
      })
    }

    dispatch(
      placeBet({
        uid: user?.uid,
        data: firebaseData,
      }),
    )

    onComplete && onComplete(true)
  }

  return {
    placeBetHandler,
    clearBetsHandler,
  }
}
